import React from "react";
import Helmet from "react-helmet";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import useTracking from "./hooks/useTracking";

import Navbar from "./components/navbar/Navbar";
import Footer from "./components/Footer";

import ScrollToTop from "./hooks/ScrollToTop";
import CookieConsentBanner from "./components/CookieConsentBanner";

import Home from "./pages/Home";
import Zonnepanelen from "./pages/zonnepanelen/Zonnepanelen";
import ZonnepanelenVoordelen from "./pages/zonnepanelen/ZonnepanelenVoordelen";
import ZonnepanelenMonoOfPoly from "./pages/zonnepanelen/ZonnepanelenMonoOfPoly";
import ZonnepanelenInstalleren from "./pages/zonnepanelen/ZonnepanelenInstalleren";
import MonitoringVerschilEnergieleverancier from "./pages/zonnepanelen/MonitoringVerschilEnergieleverancier";
import BTWTerugvraagSolar from "./pages/btwterugvraag/BTWTerugvraagSolar";
import BTWTerugvraagProcess from "./pages/btwterugvraag/BTWTerugvraagProcess";
import BTWTerugvraagBedankt from "./pages/btwterugvraag/BTWTerugvraagBedankt";
import BTWTerugvraagPrivacyverklaring from "./pages/btwterugvraag/BTWTerugvraagPrivacyverklaring";
import BTWTerugvraagAlgemeneVoorwaarden from "./pages/btwterugvraag/BTWTerugvraagAlgemeneVoorwaarden";
import Producten from "./pages/Producten";
import Conformiteitscertificaat from "./pages/support/Conformiteitscertificaat";
import Support from "./pages/support/Support";
import Corona from "./pages/Corona";
import Contact from "./pages/Contact";
import Subsidie from "./pages/Subsidie";
import Offerte from "./pages/offerte/Offerte";
import OfferteBedankt from "./pages/offerte/OfferteBedankt";
import Privacyverklaring from "./pages/Privacyverklaring";

import SolarEdgeMonitoring from "./pages/monitoring/SolarEdge";
import Afore from "./pages/monitoring/Afore";
import AforeSolarman from "./pages/monitoring/AforeSolarman";
import AforeSolarman3 from "./pages/monitoring/AforeSolarman3";
import Solplanet from "./pages/monitoring/Solplanet";
import Solis from "./pages/monitoring/Solis";
import GoodWe from "./pages/monitoring/GoodWe";
import Growatt from "./pages/monitoring/Growatt";
import Solax from "./pages/monitoring/Solax";
import SolaxPortal from "./pages/monitoring/SolaxPortal";
import SolisCloud from "./pages/monitoring/SolisCloud";
import APsystems from "./pages/monitoring/APsystems";
import Kstar from "./pages/monitoring/Kstar";

import Mobile from "./pages/campagne/Mobile";
import MobileGasprijzen from "./pages/campagne/MobileGasprijzen";
import Helmheus from "./pages/campagne/Helmheus";
import Gazetta from "./pages/campagne/Gazetta";
import Boelhuis from "./pages/campagne/Boelhuis";
import BTW from "./pages/BTW";
import Thuisbatterij from "./pages/thuisbatterij/Thuisbatterij";
import Energieleveren from "./pages/Energieleveren";
import Sitemap from "./pages/Sitemap";
import MobileActie from "./pages/campagne/MobileActie";
import ProductSolarEdge from "./pages/producten/ProductSolarEdge";
import ProductEnphase from "./pages/producten/ProductEnphase";
import ProductAfore from "./pages/producten/ProductAfore";
import ProductAPSystems from "./pages/producten/ProductAPSystems";
import ProductFlatFixFusion from "./pages/producten/ProductFlatFixFusion";
import ProductUlicaSolar from "./pages/producten/ProductUlicaSolar";
import SolarEdgeInstallerPlus from "./pages/SolarEdgeInstallerPlus";
import Laadpalen from "./pages/Laadpalen";
import EMS from "./pages/EMS";
import SupportOutage from "./pages/support/SupportOutage";
import SupportMonitoring from "./pages/support/SupportMonitoring";
import SupportDamage from "./pages/support/SupportDamage";
import SupportWifi from "./pages/support/SupportWifi";
import ProductBYDLVS from "./pages/producten/ProductBYDLVS";
import BTWTerugvraagBattery from "./pages/btwterugvraag/BTWTerugvraagBattery";
import TaylorMonitoring from "./pages/monitoring/TaylorMonitoring";
import Hoymiles from "./pages/monitoring/Hoymiles";
import ThuisbatterijInstallatie from "./pages/thuisbatterij/ThuisbatterijInstallatie";
import ThuisbatterijHoeWerktHet from "./pages/thuisbatterij/ThuisbatterijHoeWerktHet";
import OfferteDeurTotDeur from "./pages/offerte/OfferteDeurTotDeur";
import OfferteDeurTotDeurBedankt from "./pages/offerte/OfferteDeurTotDeurBedankt";

function SolarRouter() {
    useTracking(["default", "tpm"]);
    return (
        <React.Fragment>
            <Navbar/>

            <CookieConsentBanner/>

            <div className="site-content">
                <Switch>
                    <Route path="/" exact>
                        <ScrollToTop/>
                        <Home/>
                    </Route>
                    <Route path="/zonnepanelen" exact>
                        <ScrollToTop/>
                        <Zonnepanelen/>
                    </Route>
                    <Route path="/zonnepanelen/voordelen" exact>
                        <ScrollToTop/>
                        <ZonnepanelenVoordelen/>
                    </Route>
                    <Route path="/zonnepanelen/mono-of-poly" exact>
                        <ScrollToTop/>
                        <ZonnepanelenMonoOfPoly/>
                    </Route>
                    <Route path="/zonnepanelen/installeren" exact>
                        <ScrollToTop/>
                        <ZonnepanelenInstalleren/>
                    </Route>
                    <Route path="/zonnepanelen/verschil-monitoring-energieleverancier" exact>
                        <ScrollToTop/>
                        <MonitoringVerschilEnergieleverancier/>
                    </Route>
                    <Route path="/btw" exact>
                        <Redirect to="/zonnepanelen/btw/"/>
                    </Route>
                    <Route path="/zonnepanelen/btw" exact>
                        <ScrollToTop/>
                        <BTW/>
                    </Route>
                    <Route path="/subsidie" exact>
                        <Redirect to="/zonnepanelen/subsidie/"/>
                    </Route>
                    <Route path="/zonnepanelen/subsidie" exact>
                        <ScrollToTop/>
                        <Subsidie/>
                    </Route>
                    <Route path="/thuisbatterij" exact>
                        <ScrollToTop/>
                        <Thuisbatterij/>
                    </Route>
                    <Route path="/thuisbatterij/installatie" exact>
                        <ScrollToTop/>
                        <ThuisbatterijInstallatie/>
                    </Route>
                    <Route path="/thuisbatterij/hoe-werkt-het" exact>
                        <ScrollToTop/>
                        <ThuisbatterijHoeWerktHet/>
                    </Route>
                    <Route path="/thuisbatterij/btwterugvraag" exact>
                        <ScrollToTop/>
                        <BTWTerugvraagBattery/>
                    </Route>
                    <Route path="/laadpalen" exact>
                        <ScrollToTop/>
                        <Laadpalen/>
                    </Route>
                    <Route path="/ems" exact>
                        <ScrollToTop/>
                        <EMS/>
                    </Route>
                    <Route path="/solaredge-installer-plus" exact>
                        <ScrollToTop/>
                        <SolarEdgeInstallerPlus/>
                    </Route>
                    <Route path="/btwterugvraag" exact>
                        <ScrollToTop/>
                        <BTWTerugvraagSolar/>
                    </Route>
                    <Route path="/btwterugvraag/terugvraag/bedankt" exact>
                        <ScrollToTop/>
                        <BTWTerugvraagBedankt/>
                    </Route>
                    <Route path={["/btwterugvraag/terugvraag", "/btwterugvraag/terugvraag/:token"]} exact>
                        <ScrollToTop/>
                        <BTWTerugvraagProcess/>
                    </Route>
                    <Route path="/btwterugvraag/privacyverklaring" exact>
                        <ScrollToTop/>
                        <BTWTerugvraagPrivacyverklaring/>
                    </Route>
                    <Route path="/btwterugvraag/algemene-voorwaarden" exact>
                        <ScrollToTop/>
                        <BTWTerugvraagAlgemeneVoorwaarden/>
                    </Route>
                    <Route path="/producten" exact>
                        <ScrollToTop/>
                        <Producten/>
                    </Route>
                    <Route path="/producten/solaredge" exact>
                        <ScrollToTop/>
                        <ProductSolarEdge/>
                    </Route>
                    <Route path="/producten/enphase" exact>
                        <ScrollToTop/>
                        <ProductEnphase/>
                    </Route>
                    <Route path="/producten/afore" exact>
                        <ScrollToTop/>
                        <ProductAfore/>
                    </Route>
                    <Route path="/producten/apsystems" exact>
                        <ScrollToTop/>
                        <ProductAPSystems/>
                    </Route>
                    <Route path="/producten/flat-fix-fusion" exact>
                        <ScrollToTop/>
                        <ProductFlatFixFusion/>
                    </Route>
                    <Route path="/producten/ulica-solar" exact>
                        <ScrollToTop/>
                        <ProductUlicaSolar/>
                    </Route>
                    <Route path="/producten/byd-lvs" exact>
                        <ScrollToTop/>
                        <ProductBYDLVS/>
                    </Route>
                    <Route path="/support/conformiteitscertificaat">
                        <ScrollToTop/>
                        <Conformiteitscertificaat/>
                    </Route>
                    <Route path="/service">
                        <Redirect to="/support"/>
                    </Route>
                    <Route path="/support/monitoring">
                        <ScrollToTop/>
                        <SupportMonitoring/>
                    </Route>
                    <Route path="/support/storing">
                        <ScrollToTop/>
                        <SupportOutage/>
                    </Route>
                    <Route path="/support/schade">
                        <ScrollToTop/>
                        <SupportDamage/>
                    </Route>
                    <Route path="/support/wifi">
                        <ScrollToTop/>
                        <SupportWifi/>
                    </Route>
                    <Route path="/support">
                        <ScrollToTop/>
                        <Support/>
                    </Route>
                    <Route path="/contact" exact>
                        <ScrollToTop/>
                        <Contact/>
                    </Route>
                    <Route path="/offerte" exact>
                        <ScrollToTop/>
                        <Offerte/>
                    </Route>
                    <Route path="/offerte/deur-tot-deur" exact>
                        <ScrollToTop/>
                        <OfferteDeurTotDeur/>
                    </Route>
                    <Route path="/offerte/deur-tot-deur/bedankt">
                        <ScrollToTop/>
                        <OfferteDeurTotDeurBedankt/>
                    </Route>
                    <Route path="/offerte/bedankt">
                        <ScrollToTop/>
                        <OfferteBedankt/>
                    </Route>
                    <Route path="/privacyverklaring" exact>
                        <ScrollToTop/>
                        <Privacyverklaring/>
                    </Route>
                    <Route path="/corona" exact>
                        <ScrollToTop/>
                        <Corona/>
                    </Route>
                    <Route path="/energieleveren" exact>
                        <ScrollToTop/>
                        <Energieleveren/>
                    </Route>

                    {/* Monitoring */}
                    <Route path="/solaredge" exact>
                        <ScrollToTop/>
                        <SolarEdgeMonitoring/>
                    </Route>
                    <Route path="/afore" exact>
                        <ScrollToTop/>
                        <Afore/>
                    </Route>
                    <Route path="/solarman" exact>
                        <ScrollToTop/>
                        <AforeSolarman/>
                    </Route>
                    <Route path="/solarman3" exact>
                        <ScrollToTop/>
                        <AforeSolarman3/>
                    </Route>
                    <Route path="/taylor" exact>
                        <ScrollToTop/>
                        <TaylorMonitoring/>
                    </Route>
                    <Route path="/solplanet" exact>
                        <ScrollToTop/>
                        <Solplanet/>
                    </Route>
                    <Route path="/solis" exact>
                        <ScrollToTop/>
                        <Solis/>
                    </Route>
                    <Route path="/soliscloud" exact>
                        <ScrollToTop/>
                        <SolisCloud/>
                    </Route>
                    <Route path="/goodwe" exact>
                        <ScrollToTop/>
                        <GoodWe/>
                    </Route>
                    <Route path="/growatt" exact>
                        <ScrollToTop/>
                        <Growatt/>
                    </Route>
                    <Route path="/solax" exact>
                        <ScrollToTop/>
                        <Solax/>
                    </Route>
                    <Route path="/solaxportal" exact>
                        <ScrollToTop/>
                        <SolaxPortal/>
                    </Route>
                    <Route path="/apsystems" exact>
                        <ScrollToTop/>
                        <APsystems/>
                    </Route>
                    <Route path="/kstar" exact>
                        <ScrollToTop/>
                        <Kstar/>
                    </Route>
                    <Route path="/hoymiles" exact>
                        <ScrollToTop/>
                        <Hoymiles/>
                    </Route>

                    {/* Campage */}
                    <Route path="/campagne/mobile" exact>
                        <ScrollToTop/>
                        <Mobile/>
                    </Route>
                    <Route path="/mobile" exact>
                        <Redirect to="/campagne/mobile/"/>
                    </Route>
                    <Route path="/campagne/mobile-gasprijzen" exact>
                        <ScrollToTop/>
                        <MobileGasprijzen/>
                    </Route>
                    <Route path="/mobile-gasprijzen" exact>
                        <Redirect to="/campagne/mobile-gasprijzen/"/>
                    </Route>
                    <Route path="/campagne/mobile-actie" exact>
                        <ScrollToTop/>
                        <MobileActie/>
                    </Route>
                    <Route path="/mobile-actie" exact>
                        <Redirect to="/campagne/mobile-actie/"/>
                    </Route>
                    <Route path="/campagne/helmheus" exact>
                        <ScrollToTop/>
                        <Helmheus/>
                    </Route>
                    <Route path="/helmheus" exact>
                        <Redirect to="/campagne/helmheus/"/>
                    </Route>
                    <Route path="/campagne/gazetta" exact>
                        <ScrollToTop/>
                        <Gazetta/>
                    </Route>
                    <Route path="/gazetta" exact>
                        <Redirect to="/campagne/gazetta/"/>
                    </Route>
                    <Route path="/campagne/boelhuis" exact>
                        <ScrollToTop/>
                        <Boelhuis/>
                    </Route>
                    <Route path="/boelhuis" exact>
                        <Redirect to="/campagne/boelhuis/"/>
                    </Route>

                    <Route path="/sitemap" exact>
                        <ScrollToTop/>
                        <Sitemap/>
                    </Route>

                    <Route path={ ["/index", "/index.html", "/index.htm", "/index.php"] }>
                        <Redirect to="/"/>
                    </Route>
                    <Route path="/">
                        <ScrollToTop/>
                        <Helmet>
                            <title>404 Not Found - Smith Solar B.V.</title>
                        </Helmet>
                        <div className="jumbotron jumbotron-fluid jumbotron-header">
                            <div className="container">
                                <h1>404 Not Found</h1>
                            </div>
                        </div>
                    </Route>
                </Switch>
            </div>

            <Footer/>
        </React.Fragment>
    )
}

export default SolarRouter;
